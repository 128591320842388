// action types
import ApiService from "../api.service";
import {SET_ERROR } from "./auth.module";

export const POST_VERIFY_IDENTITY = process.env.VUE_APP_API_URL+'/profile/identity';
export const UPDATE_PROFILE_AFTER_DISMISS_ACTIVE = 'update-profile-after-dismiss-or-active';
export const USER_PROFILE = "profile";
export const USER_UPDATE = "update";
export const USER_UPDATE_BANK = "update-bank";
export const USER_VALIDATE_PHONE = "validate-phone";
export const USER_VERIFY_PHONE = "verify-phone";
export const USER_UPLOAD_IMAGE = "upload-avatar";
export const USER_CHANGE_PASSWORD = "change-password";
export const USER_UPLOAD_IDENTITY = "identity";
export const USER_HUMAN_RESOURCE = "profile/get-human-resource";
export const UPDATE_HUMAN_RESOURCE = "profile/update-human";
export const UPDATE_DISMISSAL_ACTIVE = "profile/dismissal-active";
export const REJECT_USER = "profile/reject-user-waiting";
export const ACCEPT_USER = "profile/accept-user-waiting";
export const GET_USER_BY_ID = "get-user-by-id";
export const SALE_UPDATE_PROFILE = "profile/sale-update-profile";

// mutation types
export const SET_USER_PROFILE = "setProfile";
export const SET_AVATAR = "setAvatar";

const state = {
};

const getters = {

};

const actions = {
    [USER_UPDATE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`${USER_PROFILE}/${USER_UPDATE}`, payload)
                .then(({ data }) => {
                    context.commit(SET_USER_PROFILE, data);
                    resolve(data);
                })
        });
    },

    [USER_PROFILE](context) {
        return new Promise(resolve => {
            ApiService.query(`/${USER_PROFILE}`)
                .then(({ data }) => {
                    context.commit(SET_USER_PROFILE, data);
                    resolve(data);
                })
        });
    },
    [USER_UPDATE_BANK](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/profile/${USER_UPDATE_BANK}`, payload).then(({data}) => {
                context.commit(SET_USER_PROFILE, data);
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
            });
        });
    },

    [USER_VALIDATE_PHONE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/profile/${USER_VALIDATE_PHONE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [USER_VERIFY_PHONE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/profile/${USER_VERIFY_PHONE}`, payload).then(({data}) => {
                context.commit(SET_USER_PROFILE, data);
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [USER_UPLOAD_IMAGE](context, payload) {
        return new Promise(resolve => {
            ApiService.upload(`/profile/${USER_UPLOAD_IMAGE}`, payload).then(({data}) => {
                context.commit(SET_USER_PROFILE, data);
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [USER_UPLOAD_IDENTITY](context, payload) {
        return new Promise(resolve => {
            ApiService.upload(`/profile/${USER_UPLOAD_IDENTITY}`, payload).then(({data}) => {
                context.commit(SET_USER_PROFILE, data);
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [USER_CHANGE_PASSWORD](context, payload) {
        return new Promise((resolve) => {
            ApiService.post(`/profile/${USER_CHANGE_PASSWORD}`, payload).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [UPDATE_HUMAN_RESOURCE](context, payload) {
        return new Promise((resolve) => {
            ApiService.post(`${UPDATE_HUMAN_RESOURCE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [UPDATE_DISMISSAL_ACTIVE](context, payload) {
        return new Promise((resolve) => {
            ApiService.post(`${UPDATE_DISMISSAL_ACTIVE}/${payload.id}`, payload).then(({data}) => {
                context.commit(UPDATE_PROFILE_AFTER_DISMISS_ACTIVE,payload.id , data)
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [USER_HUMAN_RESOURCE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${USER_HUMAN_RESOURCE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [REJECT_USER](context, payload) {
        return new Promise((resolve) => {
            ApiService.post(`${REJECT_USER}`, payload).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [ACCEPT_USER](context, payload) {
        return new Promise((resolve) => {
            ApiService.post(`${ACCEPT_USER}`, payload).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [GET_USER_BY_ID](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get('profile/show', id).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                reject(response);
            });
        });
    },

    [SALE_UPDATE_PROFILE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${SALE_UPDATE_PROFILE}/${payload.id}`, payload.formData).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                reject(response);
            });
        });
    },

};

const mutations = {
    [SET_USER_PROFILE](state, user) {
        this.state.auth.user = {...this.state.auth.user, ...user.data };
        localStorage.user = JSON.stringify(this.state.auth.user);
    },
    [SET_AVATAR](state, user) {
        this.state.auth.user.image = user.data.image;
        localStorage.user = JSON.stringify(this.state.auth.user);
    },
    [UPDATE_PROFILE_AFTER_DISMISS_ACTIVE](state, user) {
        let userState = this.state.auth.user;
        state.user = { ...userState, ...user.data };
        localStorage.user = JSON.stringify(state.user);
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
