<template>
  <router-view></router-view>
</template>
<style lang="scss">
  // 3rd party plugins css
  @import "assets/css/vendors.bundle.css";
  @import "assets/css/app.bundle.css";
  @import "assets/css/edutalk-font.css";
  @import "assets/css/fa-brands.css";
  @import "assets/css/fa-regular.css";
  @import "assets/css/fa-solid.css";
  @import "assets/css/selectize";
  @import "assets/css/fullcalendar.bundle.css";

  // Main demo style scss
  @import "assets/sass/style.vue";


  // Check documentation for RTL css
  // Update HTML with RTL attribute at public/index.html
</style>
<script>
  import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";

  export default {
    name: "EduTalk",
    mounted() {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static json (@/core/config/layout.config.json)
       */
      this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
      window.addEventListener('storage', (e) => {
        // When local storage changes, dump the list to
        // the console.
        if (this.$route.name !== 'login' && e.key === 'user'){
          window.location.reload();
        }
      });
    }
  };
</script>

