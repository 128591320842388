import ApiService from "@/core/services/api.service";
const _ = require('lodash');
//action types
export const CHI_TIET_USER = "game/detail-user";
export const LEAVE_TEAM = "game/team/left";
export const APPROVE_TEAM = "game/team/approve";
export const DENIED_TEAM = "game/team/denied";
export const DELETE_TEAM = "game/team/delete";
export const LIST_MEMBER_CLASS = "game/team/list-member";
export const ADD_MEMBER = "game/team/add-member";
export const SUB_HP = "game/sub-hp";
export const ALL_BADGES = "game/all-badges";
export const STUDENT_RESULT_VOCAB = "game/student/result/vocab";
export const STUDENT_RESULT_GRAMMAR = "game/student/result/grammar";
export const DETAIL_ATTENDANCE = "game/detail-attendance";

//mutations type

export const SET_CLANS_ACTIVE = "setClans";
export const SET_USERS_NOT_IN_CLAN = "setUsersNotInClan";
export const UPDATE_CLANS_WHEN_DELETE = "update_clans_when_delete";
export const UPDATE_CLANS_WHEN_APPROVE = "update_clans_when_approve";
export const UPDATE_CLANS_WHEN_USER_LEAVE = "update_clans_when_user_leave";
export const UPDATE_USER_WHEN_SUB_HP = "update_user_when_sub_hp";
export const UPDATE_USER_WHEN_ADD_MEMBER = "update_user_when_add_member";


const state = {
    errors: null,
    clans : [],
    usersNotInClans : []
};

const getters = {
    getAllClans(state) {
        return state.clans;
    },
    getAllUsersNotInClan(state) {
        return state.usersNotInClans;
    },
};

const actions = {
    [CHI_TIET_USER](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${CHI_TIET_USER}`, id)
                .then((res) => {
                    resolve(res);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

    [LEAVE_TEAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${LEAVE_TEAM}`, payload)
                .then((res) => {
                    // context.commit(UPDATE_CLANS_WHEN_USER_LEAVE, payload.user_clan_id);
                    resolve(res);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

    [APPROVE_TEAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${APPROVE_TEAM}`, payload)
                .then((res) => {
                    context.commit(UPDATE_CLANS_WHEN_APPROVE, payload);
                    resolve(res);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

    [DENIED_TEAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${DENIED_TEAM}`, payload)
                .then((res) => {
                    context.commit(UPDATE_CLANS_WHEN_DELETE, payload.clan_id);
                    resolve(res);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

    [DELETE_TEAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${DELETE_TEAM}`, payload)
                .then((res) => {
                    resolve(res);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

    [ADD_MEMBER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${ADD_MEMBER}`, payload)
                .then((res) => {
                    // context.commit(UPDATE_USER_WHEN_ADD_MEMBER, payload, res.data.data);
                    resolve(res);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

    [LIST_MEMBER_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${LIST_MEMBER_CLASS}`, payload)
                .then((res) => {
                    context.commit(SET_CLANS_ACTIVE, res.data.data.clan)
                    context.commit(SET_USERS_NOT_IN_CLAN, res.data.data.not_in_clan)
                    resolve(res);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

    [SUB_HP](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${SUB_HP}`, payload)
                .then((res) => {
                    context.commit(UPDATE_USER_WHEN_SUB_HP, payload);
                    resolve(res);
                })
                .catch(( { response })  => {
                    reject(response);
                });
        });
    },

    [ALL_BADGES](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${ALL_BADGES}`, id)
                .then((res) => {
                    resolve(res);
                })
                .catch(( { response })  => {
                    reject(response);
                });
        });
    },

    [STUDENT_RESULT_VOCAB](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${STUDENT_RESULT_VOCAB}`, payload)
                .then((res) => {
                    resolve(res);
                })
                .catch(( { response })  => {
                    reject(response);
                });
        });
    },

    [STUDENT_RESULT_GRAMMAR](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${STUDENT_RESULT_GRAMMAR}`, payload)
                .then((res) => {
                    resolve(res);
                })
                .catch(( { response })  => {
                    reject(response);
                });
        });
    },

    [DETAIL_ATTENDANCE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${DETAIL_ATTENDANCE}`, payload)
                .then((res) => {
                    resolve(res);
                })
                .catch(( { response })  => {
                    reject(response);
                });
        });
    },

};

const mutations = {
    [SET_CLANS_ACTIVE](state, data) {
        state.clans = data;
    },

    [SET_USERS_NOT_IN_CLAN](state, data) {
        state.usersNotInClans = data;
    },

    [UPDATE_CLANS_WHEN_DELETE](state, id) {
        let clans = state.clans;
        let index = clans.findIndex((item) => {
            return item.id === id;
        });
        if (index > -1){
            clans.splice(index,1);
        }
    },

    [UPDATE_CLANS_WHEN_APPROVE](state, payload) {
        let clans = state.clans;
        let oldClan = clans.find((item) => {
            return item.id === payload.clan_id;
        });
        oldClan.approve_status = payload.status;
    },

    [UPDATE_CLANS_WHEN_USER_LEAVE](state, user_clan_id) {
        let clans = state.clans;
        let oldClan = clans.reduce((result, item, key) => {
            if (item.user_clan.findIndex((user,index) => {return user.id === user_clan_id}) > -1){
                result = item;
            }
            return result;
        }, {});
        let userLeave = oldClan.user_clan.find(user => {
            return user.id === user_clan_id;
        });
        let usersNotInClan = state.usersNotInClans;
        usersNotInClan.push(userLeave);
        oldClan.user_clan = oldClan.user_clan.filter(user => {
            return user.id !== user_clan_id;
        })
    },

    [UPDATE_USER_WHEN_SUB_HP](state, payload) {
        let clans = state.clans;
        let oldClan = clans.reduce((result, item, key) => {
            if (item.user_clan.findIndex((user,index) => {return user.id === payload.user_clan_id}) > -1){
                result = item;
            }
            return result;
        }, {});
        let userSub = oldClan.user_clan.find(user => {
            return user.id === payload.user_clan_id;
        });
        userSub.HP -= payload.hp;
    },

    [UPDATE_USER_WHEN_ADD_MEMBER](state, payload, data) {
        let clans = state.clans;
        let oldClan = clans.find((item) => {
            return item.id === payload.clan_id
        });
        oldClan.user_clan = data;
        let addMembers = data.map( member => {
            return member.student_id;
        });
        let usersNotInClans = state.usersNotInClans;
        usersNotInClans.filter(user => {
            return !addMembers.includes(user.id)
        })
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
