import moment from 'moment-timezone';

export function convertDateToString(date) {
    return date;
}

export function formatDate(value) {
    if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
    }
}

export function titleSchedule(value) {
    switch (value) {
        case 'test':
            return 'Kiểm tra'
        case 'phu-dao':
            return 'Phụ đạo'
        default:
            return 'Buổi'
    }
}

export function agoDate(value) {
    if (value) {
        return moment(value, 'ddd MMM DD YYYY HH:mm:ss GMT Z').fromNow();
    }
}

export function formatDateTime(value){
    if (value){
        return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
    }
}

export function formatDateTimeVietnam(value) {
    if (value) {
        return moment(String(value)).format('HH:mm:ss DD-MM-YYYY')
    }
}

export function formatVND(value) {
    if (value === '' || value == null) {
        value = 0;
    }
    return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'VND'}).format(value)
}

export function formatBrTagText(value)
{
    return value ? value.replace(/(\r\n|\n\r|\r|\n)/g, '<br>') : '';
}

export function ChangeToSlug(text) {
    let slug;

    //Đổi chữ hoa thành chữ thường
    slug = text.toLowerCase();

    //Đổi ký tự có dấu thành không dấu
    slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
    slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
    slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
    slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
    slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
    slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
    slug = slug.replace(/đ/gi, 'd');
    //Xóa các ký tự đặt biệt
    slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|\]|\[|_/gi, '');
    //Đổi khoảng trắng thành ký tự gạch ngang
    slug = slug.replace(/ /gi, "-");
    //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
    //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
    slug = slug.replace(/\-\-\-\-\-/gi, '-');
    slug = slug.replace(/\-\-\-\-/gi, '-');
    slug = slug.replace(/\-\-\-/gi, '-');
    slug = slug.replace(/\-\-/gi, '-');
    //Xóa các ký tự gạch ngang ở đầu và cuối
    slug = '@' + slug + '@';
    slug = slug.replace(/\@\-|\-\@|\@/gi, '');
    //In slug ra textbox có id “slug”
    return slug;
}