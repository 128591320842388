<script>
export default {
  name: "DoughnutChart",
  mounted() {
    this.createChart()
  },
  props: {
    index: {
      type: String,
      default() {
        return ''
      }
    },
    criteria: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {
    criteria: {
      immediate: true,
      handler(val) {
        this.createChart()
      },
    },
  },
  methods: {
    createChart() {
      var canvas = document.getElementById(`doughnutChart${this.index}`);
      if (canvas) {
        var ctx = canvas.getContext('2d');

        // Dữ liệu của biểu đồ
        var data = [this.criteria.percentRedPart, this.criteria.percentGreenPart];
        var colors = ['#E53E3E', '#38A169'];
        var labels = [`${this.criteria.percentRedPart}%`, `${this.criteria.percentGreenPart}%`];

        // Tâm và bán kính của biểu đồ
        var centerX = canvas.width / 2;
        var centerY = canvas.height / 2;
        var radius = Math.min(canvas.width, canvas.height) / 2;

        // Kích thước của khoảng trống ở giữa
        var innerRadius = radius * 0.5;

        // Tổng số
        var total = data.reduce((acc, val) => acc + val, 0);

        // Vẽ biểu đồ Doughnut
        var startAngle = -Math.PI / 2;
        for (var i = 0; i < data.length; i++) {
          var sliceAngle = (2 * Math.PI * data[i]) / total;
          var endAngle = startAngle + sliceAngle;

          // Vẽ phần màu
          ctx.beginPath();
          ctx.arc(centerX, centerY, radius, startAngle, endAngle, false);
          ctx.lineTo(centerX, centerY);
          ctx.fillStyle = colors[i];
          ctx.fill();

          startAngle += sliceAngle;
        }

        // Vẽ khoảng trống ở giữa
        ctx.beginPath();
        ctx.arc(centerX, centerY, innerRadius, 0, Math.PI * 2);
        ctx.fillStyle = 'white';
        ctx.fill();

        // Vẽ nhãn
        for (var i = 0; i < data.length; i++) {
          var sliceAngle = (2 * Math.PI * data[i]) / total;
          var labelAngle = startAngle + sliceAngle / 2;
          var labelX = centerX + Math.cos(labelAngle) * (innerRadius + radius) / 2;
          var labelY = centerY + Math.sin(labelAngle) * (innerRadius + radius) / 2;
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = '14px Arial';
          if (labels[i] !== '0%') {
            ctx.fillText(labels[i], labelX, labelY);
            startAngle += sliceAngle;
          }

        }
        var text = "Tiêu chuẩn" + " " + `${this.criteria.type === 4 ? `< ${this.criteria.standard}` : `> ${this.criteria.standard}`}%`;
        ctx.fillStyle = '#38A169';
        ctx.font = '11.5px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(text, centerX, centerY);
      }

    }
  }
}
</script>

<template>
  <div>
    <canvas :id="`doughnutChart${this.index}`" width="200px" height="200px"></canvas>
  </div>
</template>

<style scoped></style>