import ApiService from "@/core/services/api.service";
const _ = require('lodash');

//action types
export const GET_ROLES = "role";
export const CREATE_ROLE = "createrole";
export const UPDATE_ROLE = "updaterole";
export const DELETE_ROLE = "deleterole";
export const GET_ROLE_BY_ID = "getrolebyid";
export const UPDATE_ROLE_INPUT_VALUE = "updateroleinputvalue";
export const GET_PERMISSIONS_BY_GUARD = "getpermissionbyguard";

//mutations type

export const SET_ROLES = "setRoles";
export const ADD_ROLES = "addRoles";
export const SET_ROLES_ERROR = "errorRoles";
export const SET_ROLE_BY_ID = "getRoleById";
export const SET_INPUT_VALUE = "setroleinputvalue";
export const SET_ROLE_PAGINATE = "setrolepaginatevalue";
export const GET_PERMISSIONS_ROLE = "getpermissionsinrolemodule";
export const SET_GUARD_PERMISSIONS = "getpermissionsbyroleguard";
export const UPDATE_LIST_ROLE = "update_list_role";

const state = {
    errors: null,
    roles: [],
    role : '',
    rolePaginatedData : '',
    permissions : [],
    guardPermissions : [],
};

const getters = {
    getAllRoles(state) {
        return state.roles;
    },
    getRoleById(state) {
        return state.role;
    },
    getRolePaginate(state) {
        return state.rolePaginatedData;
    },
    getPermissionsByGuard(state) {
        return state.permissions;
    },
    getGuardPermissions(state) {
        return state.guardPermissions;
    },
};

const actions = {
    [GET_ROLES](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("/role", credentials)
                .then(({ data }) => {
                    context.commit(SET_ROLES, data.data.data);
                    const rolePagination = {
                        total: data.data.total,  // total number of elements or items
                        per_page: data.data.per_page, // items per page
                        current_page: data.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                        total_pages: data.data.last_page // total pages in record
                    }
                    context.commit(SET_ROLE_PAGINATE,rolePagination)
                    resolve(data);
                })
                .catch(() => {
                    //context.commit(SET_ERROR, response.data.message);
                });
        });
    },
    [GET_PERMISSIONS_BY_GUARD](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("/permission-by-guard", credentials)
                .then(({ data }) => {
                    context.commit(GET_PERMISSIONS_ROLE, data.data);
                    resolve(data);
                }).catch((res) => {
                context.commit(SET_ROLES_ERROR, res.message);
            });
        });
    },
    [CREATE_ROLE](context, payload) {
        return new Promise((resolve) => {
            ApiService.post("role", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(SET_ROLES_ERROR,response.error);
                });
        });
    },
    [UPDATE_ROLE](context,payload) {
        return new Promise((resolve) => {
            ApiService.update("role", payload.id, payload.params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(SET_ROLES_ERROR,response.error);
                });
        });
    },
    [GET_ROLE_BY_ID](context, slug) {
        return new Promise((resolve) => {
            ApiService.get("role", slug)
                .then(({ data }) => {
                    context.commit(SET_ROLE_BY_ID,data.data);
                    context.commit(SET_GUARD_PERMISSIONS,data.data.guardPermissions);
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(SET_ROLES_ERROR,response.error);
                });
        });
    },
    [DELETE_ROLE](context, id) {
        return new Promise((resolve) => {
            ApiService.delete("role", id)
                .then(({ data }) => {
                    context.commit(UPDATE_LIST_ROLE,id);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ROLES_ERROR,response.error);
                });
        });
    },

    [UPDATE_ROLE_INPUT_VALUE]({ commit }, e) {
        commit(SET_INPUT_VALUE, e);
    },
};

const mutations = {
    [SET_ROLES](state, data) {
        state.roles = data;
        state.errors = {};
    },
    [ADD_ROLES](state, data) {
        state.roles.push(data);
        state.errors = {};
    },
    [SET_ROLES_ERROR](state, error) {
        state.errors = error;
    },
    [SET_ROLE_BY_ID](state, data) {
        state.role = data;
    },
    [SET_INPUT_VALUE](state, e) {
        let role = state.role;
        role[e.target.name] = e.target.value;
        state.role = role
    },
    [SET_ROLE_PAGINATE](state, data) {
        state.rolePaginatedData = data;
    },
    [SET_GUARD_PERMISSIONS](state, data) {
        state.guardPermissions = data;
    },
    [GET_PERMISSIONS_ROLE](state, data) {
        state.permissions = data;
    },
    [UPDATE_LIST_ROLE](state, id) {
        let roles = state.roles;
        let index = _.findIndex(roles,function (object) {
            return object.id === id;
        });
        roles.splice(index, 1);
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
