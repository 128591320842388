import ApiService from "@/core/services/api.service";

const _ = require('lodash');

export const GET_ANALYST = 'classrooms/learning-statistics';
export const GET_DETAIL_CLASS_INFO = 'classrooms/detail-student-in-class';
export const GET_SUPPORT_CLASS_BY_ID = 'classrooms/support-class';
export const GET_LIST_CLASS_BY_TEACHER = 'classrooms/get-class-by-teacher';
export const GET_WARNING_CLASS = 'classrooms/warning-class';

const state = {};

const getters = {};

const actions = {
    [GET_ANALYST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_ANALYST}`, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [GET_LIST_CLASS_BY_TEACHER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_LIST_CLASS_BY_TEACHER}`, payload)
                .then((res) => {
                    resolve(res);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [GET_WARNING_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_WARNING_CLASS}`, payload)
                .then((res) => {
                    resolve(res);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [GET_DETAIL_CLASS_INFO](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_DETAIL_CLASS_INFO}`, payload)
                .then((res) => {
                    resolve(res);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [GET_SUPPORT_CLASS_BY_ID](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_SUPPORT_CLASS_BY_ID}`, payload)
                .then((res) => {
                    resolve(res);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};

const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters,
};
