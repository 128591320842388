import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ErrorService from "@/core/services/ErrorService";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

import { ValidationObserver, ValidationProvider, extend, localize, configure} from 'vee-validate';
import vi from 'vee-validate/dist/locale/vi.json';
import * as rules from 'vee-validate/dist/rules'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

import VueTheMask from 'vue-the-mask'
import VCalendar from 'v-calendar';
Vue.use(VCalendar, { componentPrefix: 'edutalk' });
import * as filters from '@/core/filters'; // global filters
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG,SET_OLD_PATH } from "@/core/services/store/config.module";
import VueLogger from 'vuejs-logger';
const isProduction = process.env.NODE_ENV === 'production';
const options = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};

Vue.use(VueLogger, options);
// install rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

configure({
    classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
        // ...
    }
});

localize('vi', vi);
moment.locale('vi');
// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.config.productionTip = false;
// Handle all Vue errors
Vue.config.errorHandler = (error) => ErrorService.onError(error);

const DEFAULT_TIMEZONE = 'Asia/Bangkok'; // UTC+7

export function setDefaultTimezone() {
    return moment.tz.setDefault(DEFAULT_TIMEZONE);
}

export function getCurrentTime() {
    return moment().tz(DEFAULT_TIMEZONE);
}

export function formatDateTime(dateTime) {
    return moment(dateTime).tz(DEFAULT_TIMEZONE).format('YYYY-MM-DD HH:mm:ss');
}

setDefaultTimezone();

Vue.use(VueMoment, {moment});

Vue.use(VCalendar, { componentPrefix: 'edutalk' });
Vue.use(VueTheMask);

// register global utility filters.
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
    }
});
// Global 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import "@/core/plugins/treeselect";
import "@/core/plugins/element.io";
import "@/core/plugins/apexcharts";
import "@/core/plugins/zingcharts";
import "@/core/plugins/good-table";
import "@/core/config/customMethod";
import "@/core/plugins/fb-messenger";
// API service init
ApiService.init();

var firebaseConfig = {
        apiKey: "AIzaSyBuvmAcRr5yMLJu4YSqx9kVP-hTQFPpfYM",
        authDomain: "edutalk-71810.firebaseapp.com",
        databaseURL: "https://edutalk-71810.firebaseio.com",
        projectId: "edutalk-71810",
        storageBucket: "edutalk-71810.appspot.com",
        messagingSenderId: "383005973421",
        appId: "1:383005973421:web:df9086bf9c496c025bdf24",
        measurementId: "G-PZ9XL79R82"
}

import firebase from 'firebase';
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

// Remove this to disable mock API
// MockService.init();

router.beforeEach(async (to, from, next) => {
    // Ensure we checked auth before each page load.
    const { authorize } = to.meta;
    let userCode = store.getters.getUserCode;
    if(authorize){
        let currentUser = store.getters.currentUser;
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return next({ path: '/login', query: { returnUrl: to.path } });
        }
        // check if route is restricted by role
        if (authorize.length && !authorize.includes( currentUser.user.role)) {
            // role not authorised so redirect to home page
            return next({ path: '/' });
        }
    }
    if ((to.name === 'otp' || to.name === 'new-password') && !userCode){
        return next({ path: '/login'})
    }
    if(to.name !=='login' ){
        store.dispatch(SET_OLD_PATH,to.path);
    }

    if(to.name==='maintain') return next();

    Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG);

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

const app = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
});
app.$mount("#app");
