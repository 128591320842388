import ApiService from "@/core/services/api.service";
const _ = require('lodash');
//action types
export const GET_PERMISSION = "permission";
export const CREATE_PERMISSION = "createpermission";
export const UPDATE_PERMISSION = "updatepermission";
export const DELETE_PERMISSION = "deletepermission";
export const GET_PERMISSION_BY_ID = "getpermissionbyid";
export const UPDATE_INPUT_VALUE = "updatepermissioninputvalue";


//mutations type

export const SET_PERMISSIONS = "setPermissions";
export const ADD_PERMISSIONS = "addPermissions";
export const SET_PERMISSIONS_ERROR = "errorPermissions";
export const SET_PERMISSION_BY_ID = "getPermissionById";
export const SET_INPUT_VALUE = "setpermissioninputvalue";
export const SET_PERMISSION_PAGINATE = "setpermissionpaginatevalue";
export const UPDATE_LIST_PERMISSION = "update_list_permission";

const state = {
  errors: null,
  permissions: [],
  permission : '',
  permissionPaginatedData : '',
};

const getters = {
  getAllPermissions(state) {
    return state.permissions;
  },
  getPermissionById(state) {
    return state.permission;
  },
  getPermissionPaginate(state) {
    return state.permissionPaginatedData;
  },

};

const actions = {
  [GET_PERMISSION](context, credentials) {
    return new Promise((resolve) => {
      ApiService.query("/permission", credentials)
        .then(({ data }) => {
          context.commit(SET_PERMISSIONS, data.data.data);
          const permissionPagination = {
            total: data.data.total,  // total number of elements or items
            per_page: data.data.per_page, // items per page
            current_page: data.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
            total_pages: data.data.last_page // total pages in record
          }
          context.commit(SET_PERMISSION_PAGINATE,permissionPagination);
          resolve(data);
        })
        .catch((response) => {
          context.commit(SET_PERMISSIONS_ERROR, response.error);
        });
    });
  },
  [CREATE_PERMISSION](context, payload) {
    return new Promise((resolve) => {
      ApiService.post("permission", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((response) => {
          console.log(response)
          context.commit(SET_PERMISSIONS_ERROR,response.error);
        });
    });
  },
  [UPDATE_PERMISSION](context,payload) {
    return new Promise((resolve) => {
      ApiService.update("permission", payload.id, payload.params)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((response) => {
            context.commit(SET_PERMISSIONS_ERROR,response.error);
          });
    });
  },
  [GET_PERMISSION_BY_ID](context, slug) {
    return new Promise((resolve) => {
      ApiService.get("permission", slug)
          .then(({ data }) => {
            context.commit(SET_PERMISSION_BY_ID,data)
            resolve(data);
          })
          .catch((response) => {
            context.commit(SET_PERMISSIONS_ERROR,response.error);
          });
    });
  },
  [DELETE_PERMISSION](context, id) {
    return new Promise((resolve) => {
      ApiService.delete("permission", id)
        .then(({ data }) => {
          context.commit(UPDATE_LIST_PERMISSION,id);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_PERMISSIONS_ERROR,response.error);
        });
    });
  },

  [UPDATE_INPUT_VALUE]({ commit }, e) {
    commit(SET_INPUT_VALUE, e);
  },
};

const mutations = {
  [SET_PERMISSIONS](state, data) {
    state.permissions = data;
    state.errors = {};
  },
  [ADD_PERMISSIONS](state, data) {
    state.permissions.push(data);
    state.errors = {};
  },
  [SET_PERMISSIONS_ERROR](state, error) {
    state.errors = error;
  },
  [SET_PERMISSION_BY_ID](state, data) {
    state.permission = data.data;
  },
  [SET_INPUT_VALUE](state, e) {
    let permission = state.permission;
    permission[e.target.name] = e.target.value;
    state.permission = permission
  },
  [SET_PERMISSION_PAGINATE](state, data) {
    state.permissionPaginatedData = data;
  },
  [UPDATE_LIST_PERMISSION](state, id) {
    let permissions = state.permissions;
    let index = _.findIndex(permissions,function (object) {
      return object.id === id;
    });
    permissions.splice(index, 1);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
