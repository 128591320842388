<script>
export default {
  name: "TableModal",
  props:{
    typeCard: {
      type: Number,
      required:true,
      default: null
    },
    dataTable: {
      type: Array,
      required: true,
      default: null
    },
    tableHeader: {
      type: Array,
      required: true,
      default: null
    },
    loading : {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
}
</script>

<template>
  <div class="mt-2 table-responsive" style="height: auto ; max-height: 300px">
    <table class="table table-bordered  table-vertical-center ">
      <thead>
        <tr class="" style="background-color: #F8F8F8 ; font-size: 13px; position: sticky; top: 0">
          <th v-for="(item,index) in tableHeader" :key="index">{{item}}</th>
        </tr>
      </thead>
      <tbody v-if="loading">
        <tr>
          <td colspan="5" class="text-center">
            <i class="el-icon-loading"></i>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="dataTable.length > 0 && typeCard === 1">
        <tr v-for="(item,index) in dataTable" :key="index">
          <td>{{index + 1}}</td>
          <td>{{item?.name}}</td>
          <td>{{item?.total_leave}}</td>
          <td>{{item?.permitted_leave}}</td>
          <td>{{item?.leaving_without_permission}}</td>
        </tr>
      </tbody>
      <tbody v-else-if="dataTable.length > 0 && typeCard === 2">
      <tr v-for="(item,index) in dataTable" :key="index">
        <td>{{index + 1}}</td>
        <td>{{item?.name}}</td>
        <td>{{item?.total}}</td>
        <td>{{item?.total_unfinished_sessions}}</td>
        <td>{{item?.total_score_low}}</td>
      </tr>
      </tbody>
      <tbody v-else-if="dataTable.length > 0 && (Math.floor(typeCard) == 3)">
        <tr v-for="(item,index) in dataTable" :key="index">
          <td>{{index + 1}}</td>
          <td>{{item?.course_student?.user?.name}}</td>
          <td>{{item?.avg_score}}</td>
        </tr>
      </tbody>
      <tbody v-else-if="dataTable.length > 0 && typeCard === 4">
      <tr v-for="(item,index) in dataTable" :key="index">
        <td>{{index + 1}}</td>
        <td>{{item?.lesson}}</td>
        <td>{{item?.errors}}</td>
      </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="5" class="text-center">
            Không có dữ liệu
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

</style>