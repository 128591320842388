import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "./../../router";
import Swal from "sweetalert2";
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css'

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

    // Add a request interceptor
    Vue.axios.interceptors.request.use(
      function (config) {
        Nprogress.start();
        // config.headers.Authorization = `Bearer ${JwtService.getToken()} `;
        return config;
      },
      function (error) {
        Nprogress.done();
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    Vue.axios.interceptors.response.use(
      function (response) {
        Nprogress.done();

        logResponse(response);
        return response;
      },
      function (error) {
        logResponse(error);
        const { status } = error.response;
        if (status === 503) {
          if (localStorage.getItem("maintain") === null || localStorage.getItem("maintain") !== error.response.data.data) {
            localStorage.setItem("maintain", error.response.data.data);
          }
          router.push({ name: "maintain" });
        }

        if (status === 401) {
          Swal.fire({
            title: 'Phiên đăng nhập của bạn đã hết hạn',
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: `Đồng ý`,
          }).then(() => {
            /* Read more about isConfirmed, isDenied below */
            localStorage.removeItem("user");
            router.push({ name: "login" });
          })
        }
        Nprogress.done();
        return Promise.reject(error);
      }
    );
  },
  query(resource, params) {
    return Vue.axios.get(resource, {
      withCredentials: true,
      credentials: "include",
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json',

      },
      params: params
    }
    );
  },


  queryNode(resource, params) {
    return Vue.axios.get(resource, {
      withCredentials: true,
      credentials: "include",
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json',
        Authorization: `Bearer ${JwtService.getToken()} `
      },
      params: params
    }
    ).catch((error) => {
      // console.log(error);
      throw new Error(`[Edutalk] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {*}
   */
  get(resource, slug = "", params) {
    return Vue.axios.get(`${resource}/${slug}`, {
      withCredentials: true,
      credentials: "include",
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json',
      },
      params: params
    }
    );
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params, {
      withCredentials: true,
      credentials: "include",
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json',
      },
    });
  },

  /**
   * Set the POST HTTP request with fromData
   * @param resource
   * @param params
   * @returns {*}
   */
  upload(resource, params) {
    return Vue.axios.post(`${resource}`, params, {
      withCredentials: true,
      credentials: "include",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      }
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params, {
      withCredentials: true,
      credentials: "include",
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json',
      },
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params, {
      withCredentials: true,
      credentials: "include",
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json',
      },
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @param slug: string
   * @returns {*}
   */
  delete(resource, slug = "") {
    return Vue.axios.delete(`${resource}/${slug}`, {
      withCredentials: true,
      credentials: "include",
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json',
      },
    }).catch((error) => {
      // console.log(error);
      throw new Error(`[Edutalk] ApiService ${error}`);
    });
  },
};

export function bytesToBase64(bytes) {
  const binString = Array.from(bytes, (x) => String.fromCodePoint(x)).join("");
  return btoa(binString);
}
export default ApiService;


export function logResponse(response) {
  try {
    if (["get"].includes(response.config.method)) {
      let key = response.config.method;
      let data = localStorage.getItem(key);
      if (!data && !localStorage.getItem("post")) {
        localStorage.clear();
      }
      let items = JSON.parse(data ?? "[]");
      if (items.length > 20) {
        items = items.splice(0, 20);
      }
      items.push(bytesToBase64(new TextEncoder().encode(JSON.stringify({ data: response.data, time: (new Date()).getTime(), url: response.request.responseURL }))));
      localStorage.setItem(key, JSON.stringify(items));
    }
    if (["post"].includes(response.config.method)) {
      let key = response.config.method;
      let data = localStorage.getItem(key);
      if (!data && !localStorage.getItem("get")) {
        localStorage.clear();
      }
      let items = JSON.parse(data ?? "[]");
      if (items.length > 20) {
        items = items.splice(0, 20);
      }
      items.push(bytesToBase64(new TextEncoder().encode(JSON.stringify({
        data: response.data,
        time: (new Date()).getTime(),
        request: response.config.data,
        url: response.request.responseURL
      }))));
      localStorage.setItem(key, JSON.stringify(items));
    }
  }
  catch (e) {
  }
} 