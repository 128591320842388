import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { USER_PROFILE} from "./profile.module";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const FORGOT_PASSWORD = "forgot-password";
export const RESEND_OTP = "resend-otp";
export const USER_VERIFY_OTP = "verify-otp";
export const CHANGE_NEW_PASSWORD = "change-new-password";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_USER_CODE = "set-user-code";
export const REMOVE_USER_CODE = "remove-user-code";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: JSON.parse(localStorage.getItem(`user`))||{},
  isAuthenticated: !!JwtService.getToken(),
  userCode : localStorage.getItem(`user_code`) || '',
  userEmail : JSON.parse(localStorage.getItem(`user_email`)) || '',
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getUserCode(state) {
    return state.userCode;
  },
  getUserEmail(state) {
    return state.userEmail;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/login", credentials).then(() => {
        return new Promise(() => {
          ApiService.query(`/${USER_PROFILE}`).then(({ data }) => {
            context.commit(SET_AUTH, data.data);
            resolve(data);
          })
        });
      }).catch(( {response} ) => {
          context.commit(SET_ERROR, response && response.data ? response.data.message : '');
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve, reject) => {
      ApiService.post('logout')
          .then(({data}) => {
            context.commit(PURGE_AUTH);
            localStorage.removeItem('user');
            resolve(data)
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response && response.data ? response.data.errors : '');
            reject(response)
          });
    })

  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  /**
   * api quên mật khẩu gửi otp
   * @param context
   * @param payload
   * @returns {Promise}
   */
  [FORGOT_PASSWORD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${FORGOT_PASSWORD}`, payload)
        .then(({ data }) => {
          context.commit(SET_USER_CODE, data.data)
          resolve(data);
        })
        .catch(( {response} ) => {
          reject(response);
        });
    });
  },
  /**
   * api gửi lại otp
   * @param context
   * @param payload
   * @returns {Promise}
   */
  [RESEND_OTP](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${RESEND_OTP}`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(( {response} ) => {
          reject(response);
        });
    });
  },
  /**
   * api mã xác thực
   * @param context
   * @param payload
   * @returns {Promise}
   */
  [USER_VERIFY_OTP](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${USER_VERIFY_OTP}`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(( {response} ) => {
          reject(response);
        });
    });
  },
  /**
   * api thay đổi mật khẩu
   * @param context
   * @param payload
   * @returns {Promise}
   */
  [CHANGE_NEW_PASSWORD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${CHANGE_NEW_PASSWORD}`, payload)
        .then(({ data }) => {
          context.commit(REMOVE_USER_CODE)
          resolve(data);
        })
        .catch(( {response} ) => {
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      // ApiService.setHeader();
      // ApiService.post('verify')
      //   .then(({ data }) => {
      //     context.commit(SET_AUTH, data);
      //   })
      //   .catch(({ response }) => {
      //     context.commit(SET_ERROR, response && response.data ? response.data.errors : '');
      //   });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    return new Promise(resolve => {
      ApiService.post("/change-password", payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(( {response} ) => {
            if (response?.data?.data?.message_validate_form) {
              context.commit(SET_ERROR, response.data.data.message_validate_form);
            } else {
              context.commit(SET_ERROR, response && response.data ? response.data.message : '');
            }
          });
    });
  }
};

const mutations = {
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    localStorage.setItem('user', JSON.stringify(user));
    state.errors = {};
    JwtService.saveToken('Edutalk');
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [SET_USER_CODE](state, user) {
    localStorage.setItem('user_code', JSON.stringify(user.id));
    localStorage.setItem('user_email', JSON.stringify(user.email));
    state.userCode = user.id;
    state.userEmail = user.email;
  },
  [REMOVE_USER_CODE](state, user) {
    localStorage.removeItem('user_code');
    localStorage.removeItem('user_email');
    state.userCode = '';
    state.userEmail = '';
  },
  [PURGE_AUTH](state) {
    JwtService.saveToken('Edutalk');
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
