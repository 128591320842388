import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import service from "./service.module";

import permission from "./user/permission.module";
import user from "./user/users.module";
import accountType from "./user/account-type.module";
import role from "./user/role.module";
import classes from "./classes/classes.module";
import teacher from "./user/teacher.module";
import supportClass from "@/core/services/store/supportClass/supportClass.module";

Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        auth,
        htmlClass,
        config,
        breadcrumbs,
        profile,
        service,
        permission,
        user,
        accountType,
        role,
        classes,
        teacher,
        supportClass,
    }
});
export default store;
