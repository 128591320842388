import CriteriaOfClass from "../../views/pages/home/component/CriteriaOfClass.vue";

const ID_TOKEN_KEY = "_token";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default {
    components: {CriteriaOfClass},
    getToken, saveToken, destroyToken };
