import ApiService from "@/core/services/api.service";
//action types
export const CREATE_ACCOUNT_TYPE = "create_account_type";
export const UPDATE_ACCOUNT_TYPE = "update_account_type";
export const DELETE_ACCOUNT_TYPE = "delete_account_type";
export const GET_ACCOUNT_TYPE_BY_ID = "get_account_type_by_id";
export const GET_ACCOUNT_TYPE = "get_account_type_index";
export const GET_POSITIONS = "get_positions";
export const GET_ROLES = "get_role";
export const SET_ROLES = "set_role";
export const SET_ACCOUNT_TYPE_INDEX = "set_role";
export const SET_ACCOUNT_TYPE_BY_ID = "set_account_type_by_id";
export const SET_ACCOUNT_TYPE_ERROR = "set_account_type_error";
export const SET_ERROR_MESSAGE = "set_error_message";


// export const UPDATE_PERMISSION = "update";

const state = {
    errors: null,
    roles: [],
    accountTypes: []
};

const getters = {
    roles(state) {
        return state.accountTypes;
    },
    accountTypes(state) {
        return state.roles;
    },
    errorsAccountType(state) {
        return state.errors;
    },
};

const actions = {
    [CREATE_ACCOUNT_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.post('account-type', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                context.commit(SET_ERROR_MESSAGE, response.response.data);
            });
        })
    },
    [UPDATE_ACCOUNT_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.update('account-type', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [DELETE_ACCOUNT_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.delete('account-type', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_ACCOUNT_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.query('account-type', payload).then(({data}) => {
                context.commit(SET_ACCOUNT_TYPE_INDEX, data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_ACCOUNT_TYPE_BY_ID](context, slug) {
        return new Promise((resolve) => {
            ApiService.get("account-type", slug)
                .then(({data}) => {
                    context.commit(SET_ACCOUNT_TYPE_BY_ID, data)
                    resolve(data);
                })
                .catch((response) => {
                    context.commit(SET_ACCOUNT_TYPE_ERROR, response.error);
                });
        });
    },
    [GET_POSITIONS](context, slug) {
        return new Promise((resolve) => {
            ApiService.query("users/positions", slug)
                .then(({data}) => {
                    // context.commit('', data)
                    resolve(data);
                })
                .catch((response) => {
                    context.commit('', response.error);
                });
        });
    },
    // Get roles
    [GET_ROLES](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("/role", credentials)
                .then(({data}) => {
                    context.commit(SET_ROLES, data.data);
                    resolve(data);
                })
                .catch((response) => {
                    console.log(response);
                });
        });
    },
};

const mutations = {
    [SET_ROLES](state, roles) {
        state.roles = roles;
    },
    [SET_ACCOUNT_TYPE_INDEX](state, accountTypes) {
        state.accountTypes = accountTypes;
    },
    [SET_ERROR_MESSAGE](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
