import ApiService from "@/core/services/api.service";
const _ = require('lodash');
//action types
export const CREATE_STAFF = "create_staff";
export const UPDATE_STAFF = "update_staff";
export const GET_USER_BY_ID = "get_user_by_id";
export const SET_USERS_INDEX = "set_user_index";
export const SEARCH_BY_MA_CAP_TREN = "search_by_ma_cap_tren";
export const GET_USERS = "list-user";
export const CREATE_USER = "createuser";
export const UPDATE_USER = "updateuser";
export const DELETE_USER = "delete_user";
export const UPDATE_USER_INPUT_VALUE = "updateuserinputvalue";
export const GET_USER_LOG_HISTORIES = "get_user_log_histories";
export const GET_USER_LOG_TIME_WORK = "get_user_log_time_work";
export const GET_USER_LOG_ACTION_DATA = "get_user_log_action_data";
export const DISMISS_USER = "dismiss_user";
export const ACTIVE_USER = "active_user";

//mutations types
export const SET_USERS = "setUsers";
export const ADD_USERS = "addUsers";
export const SET_USERS_ERROR = "errorUsers";
export const SET_USER_BY_ID = "getUserById";
export const SET_INPUT_VALUE = "setuserinputvalue";
export const SET_USER_PAGINATE = "setuserpaginatevalue";
export const SET_USER_LOG_PAGINATE = "set_user_log_paginate_value";
export const SET_USER_LOG_HISTORIES = "set_user_log_histories";
export const SET_USER_LOG_DATA_CHART = "set_user_log_data_chart";
export const SET_USER_LOG_ACTION_DATA = "set_user_log_action_data";
export const UPDATE_LIST_USER = "set_users_when_active_user";
export const UPDATE_LIST_USER_WHEN_DELETE = "set_users_when_delete_user";


const state = {
    errors: null,
    users: [],
    user : '',
    userPaginatedData : [],
    userLogPaginatedData : '',
    logHistories: '',
    logActionHistories: '',
    timeChartData : '',
};

const getters = {
    getAllUsers(state) {
        return state.users;
    },
    getUserById(state) {
        return state.user;
    },
    getUserPaginate(state) {
        return state.userPaginatedData;
    },
    getUserLogHistories(state) {
        return state.logHistories;
    },
    getUserLogHistoriesPaginate(state) {
        return state.userLogPaginatedData;
    },
    getUserLogChartData(state) {
        return state.timeChartData;
    },
    getUserActionLogData(state) {
        return state.logActionHistories;
    },
};

const actions = {
    [GET_USERS](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("/list-user", credentials)
                .then(({data}) => {
                    context.commit(SET_USERS, data.data.data);
                    const userPagination = {
                        total: data.data.total,  // total number of elements or items
                        per_page: data.data.per_page, // items per page
                        current_page: data.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                        total_pages: data.data.last_page // total pages in record
                    }
                    context.commit(SET_USER_PAGINATE, userPagination)
                    resolve(data);
                })
                .catch(() => {
                    //context.commit(SET_ERROR, response.data.message);
                });
        });
    },

    [GET_USER_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('users', id).then(({data}) => {
                // context.commit(SET_USER_BY_ID, data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_USER_LOG_ACTION_DATA](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("/user/log-action-history/" + payload.id, payload.params)
                .then(({data}) => {
                    context.commit(SET_USER_LOG_ACTION_DATA, data.data.data);
                    const userLogActionPagination = {
                        total: data.data.total,  // total number of elements or items
                        per_page: data.data.per_page, // items per page
                        current_page: data.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                        total_pages: data.data.last_page // total pages in record
                    }
                    context.commit(SET_USER_LOG_PAGINATE, userLogActionPagination)
                    resolve(data);
                })
                .catch(() => {
                });
        });
    },
    [GET_USER_LOG_HISTORIES](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("/user/log-history/" + payload.id, payload.params)
                .then(({data}) => {
                    context.commit(SET_USER_LOG_HISTORIES, data.data.data);
                    const userLogPagination = {
                        total: data.data.total,  // total number of elements or items
                        per_page: data.data.per_page, // items per page
                        current_page: data.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                        total_pages: data.data.last_page // total pages in record
                    }
                    context.commit(SET_USER_LOG_PAGINATE, userLogPagination)
                    resolve(data);
                })
                .catch(() => {
                });
        });
    },
    [GET_USER_LOG_TIME_WORK](context, id) {
        return new Promise((resolve) => {
            ApiService.get("/user/log-history-by-day", id)
                .then(({data}) => {
                    context.commit(SET_USER_LOG_DATA_CHART, data.data);
                    resolve(data);
                })
                .catch(() => {
                });
        });
    },
    [CREATE_STAFF](context, payload) {
        return new Promise(resolve => {
            ApiService.post('create-staff', payload).then(({data}) => {
                // context.commit('', data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [UPDATE_STAFF](context, payload) {
        return new Promise(resolve => {
            ApiService.post('update-staff/' + payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [SEARCH_BY_MA_CAP_TREN](context, payload) {
        return new Promise(resolve => {
            ApiService.query('users', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [DISMISS_USER](context, payload) {
        return new Promise(resolve => {
            ApiService.post('user/dismiss/'+ payload.id, payload.params).then(({data}) => {
                context.commit(UPDATE_LIST_USER,data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [ACTIVE_USER](context, payload) {
        return new Promise(resolve => {
            ApiService.post('user/active/'+ payload.id, payload.params).then(({data}) => {
                context.commit(UPDATE_LIST_USER,data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [DELETE_USER](context, id) {
        return new Promise(resolve => {
            ApiService.delete('user', id).then(({data}) => {
                context.commit(UPDATE_LIST_USER_WHEN_DELETE, id);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

};


const mutations = {
    [SET_USERS](state, data) {
        state.users = data;
        state.errors = {};
    },
    [ADD_USERS](state, data) {
        state.users.push(data);
        state.errors = {};
    },
    [SET_USERS_ERROR](state, error) {
        state.errors = error;
    },
    [SET_USER_BY_ID](state, data) {
        state.user = data;
    },
    [SET_INPUT_VALUE](state, e) {
        let user = state.user;
        user[e.target.name] = e.target.value;
        state.user = user
    },
    [SET_USER_PAGINATE](state, data) {
        state.userPaginatedData = data;
    }
    , [SET_USER_LOG_PAGINATE](state, data) {
        state.userLogPaginatedData = data;
    },
    [SET_USER_LOG_HISTORIES](state, data) {
        state.logHistories = data;
    },
    [SET_USER_LOG_DATA_CHART](state, data) {
        state.timeChartData = data;
    },
    [SET_USER_LOG_ACTION_DATA](state, data) {
        state.logActionHistories = data;
    },
    [UPDATE_LIST_USER](state, user){
        let users = state.users;
        let oldUser = _.find(users,function (object) {
            return object.id === user.id;
        });
        oldUser.status = user.status;
    },
    //
    [UPDATE_LIST_USER_WHEN_DELETE](state, id){
        let users = state.users;
        let indexUser = _.findIndex(users,function (object) {
            return object.id === id;
        });
        users.splice(indexUser,1);
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
