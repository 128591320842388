import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
const routes = [
    {
        path: "/",
        redirect: "/dashboard",
        component: () => import("@/views/layout/Layout"),
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                component: () => import("@/views/pages/home/home.vue"),
            },
            {
                path: "/profile",
                name: "profile",
                component: () => import("@/views/pages/user/edutalk-profile"),
            },
            {
                path: "/classrooms",
                name: "classrooms",
                component: () => import("@/views/pages/classrooms/list"),
            },
            {
                path: "/classrooms/:id/lo-trinh",
                name: "schedule",
                component: () => import("@/views/pages/classrooms/schedule"),
            },
            {
                path: "/classrooms/comment-student/:id",
                name: "comment-student",
                component: () => import("@/views/pages/classrooms/CommentStudent"),
            },
            {
                path: "schedule-class/list_mark_homework/:id",
                name: "list_mark_homework",
                component: () => import("@/views/pages/classrooms/ListHomeworkStudent"),
            },
            {
                path: "mark-homework/:id",
                name: "mark-homework",
                component: () => import("@/views/pages/classrooms/MarkHomework.vue")
            },
            {
                path: "/classrooms/:id/team",
                name: "team",
                component: () => import("@/views/pages/team"),
            },
            {
                path: "/salary",
                name: "salary",
                component: () => import("@/views/pages/report/salary"),
            },
            {
                path: "/notification",
                name: "notification",
                component: () => import("@/views/pages/notification/notification"),
            },
            {
                path: "/listSchedule",
                name: "listSchedule",
                component: () => import("@/views/pages/study/listSchedule"),
            },
            {
                path: "/Redirectquantumleap",
                name: "Redirectquantumleap",
                component: () => import("@/views/pages/study/Redirectquantumleap"),
            },
            {
                path: "/slide/:id/classroom/:classroom_id",
                name: "content-slide",
                component: () => import("@/views/pages/classrooms/slide-content"),
            },
            {
                path: "/schedule/homework/:classroom_id/learning_schedule/:learning_schedule_id",
                name: "schedule-homework",
                component: () => import("@/views/pages/classrooms/schedule-homework"),
            },
            {
                path: "/schedule/homework/:classroom_id/learning_schedule/:learning_schedule_id/student/:student_id/lesson/:lesson",
                name: "student-homework",
                component: () => import("@/views/pages/classrooms/student-homework"),
            },
            {
                path: "content-homework/:id",
                name: "content-homework",
                component: () => import("@/views/pages/classrooms/homework-common"),
            },
            {
                path: "/classrooms/:id/upload",
                name: "upload",
                component: () => import("@/views/pages/upload"),
            },
            {
                path: "/home",
                name: "home",
                component: () => import("@/views/pages/home/home"),
            },
            {
                path: "/classrooms/:id/team-kid",
                name: "team-kid",
                component: () => import("@/views/pages/team-kid"),
            },
            // End hệ thống thông tin
            {
                path: "/404",
                name: "404",
                component: () => import("@/views/error/error"),
            }
        ]
    },
    {
        path: "/",
        component: () => import("@/views/pages/auth/Login"),
        children: [
            {
                name: "login",
                path: "/login",
                component: () => import("@/views/pages/auth/Login"),
            }
        ],
    },
    {
        path: "/",
        component: () => import("@/views/pages/auth/ForgotPassword"),
        children: [
            {
                name: "forgot-password",
                path: "/forgot-password",
                component: () => import("@/views/pages/auth/ForgotPassword"),
            }
        ],
    },
    {
        path: "/",
        component: () => import("@/views/pages/auth/Otp"),
        children: [
            {
                name: "otp",
                path: "/otp",
                component: () => import("@/views/pages/auth/Otp"),
            }
        ],
    },
    {
        path: "/",
        component: () => import("@/views/pages/auth/NewPassword"),
        children: [
            {
                name: "new-password",
                path: "/new-password",
                component: () => import("@/views/pages/auth/NewPassword"),
            }
        ],
    },
    {
        name: "maintain",
        path: "/maintain",
        component: () => import("@/views/pages/Maintain"),
    },
];

const router = new Router({
    mode: "history",
    routes,
});

export default router;
