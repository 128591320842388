// action types
import ApiService from "@/core/services/api.service";
export const STUDENT_HAS_ATTENDANCE = "game/student-has-attendance";
export const STUDENT_HAS_GIFT = "game/gift-of-lesson-class";
export const READY_MARK_HOMEWORK = "game/ready-mark-homework";
export const CLASSROOM_SCHEDULE = "game/classroom/schedule";
export const ACTION_ATTENDANCE = "game/action-attendance";
export const ACTION_ATTENDANCE_PHU_DAO = "game/tutoring/attendance";
export const ATTENDANCE_LESSON_PHU_DAO = "game/tutoring/list-student";
export const LIST_COMMENT_PHU_DAO = "game/comment-lesson/list/...";
export const POST_COMMENT_PHU_DAO = "game/tutoring/comment";
export const LIST_COMMENT = "game/comment-lesson/list";
export const POST_COMMENT = "game/comment-lesson/upsert";
export const GET_AUTO_COMMENT = "game/comment-lesson/v2/get-auto-comment";
export const GET_LIST_STUDENT_COMMENT = "game/comment-lesson/v2/feedback-students";
export const POST_COMMENT_STUDENT = "game/comment-lesson/v2/upsert";
export const GET_DATA_CREATE_TUTORING = "game/tutoring/create";
export const EDIT_TUTORING = "game/tutoring/edit";
export const GET_DATA_EDIT_TUTORING = "game-tutoring-edit";
export const DELETE_TUTORING = "game/tutoring";
export const STORE_TUTORING = "game/tutoring/store";
export const EXAM_LIST_MARK = "game/classroom/exam/list-mark";
export const EXAM_MARK = "game/classroom/exam/mark";
export const EXAM_MARK_POST = "game/classroom/exam/mark/post";
export const RESULT_EXAM = "game/classroom/exam/review";
export const LIST_EMPTY_ROOMS = "game/tutoring/room-can-use";
export const REQUEST_OFF_LESSON = "game/classroom/off-lesson";
export const CANCEL_REQUEST_OFF_LESSON = "game/classroom/cancel-off-lesson";
export const GET_NEXT_DATE_LESSON = "game/classroom/get-next-lesson";
export const ATTENDANCE_LESSON = "game/attendance-lesson";
export const GET_SCHEDULE_HOMEWORK = "game/classroom/schedule/homeworks";
export const SHOW_DETAIL_LEARNING_SCHEDULE = "game/classroom/schedule/show";
export const SHOW_HOMEWORK_RESULT = "game/classroom/schedule/result";
export const CHECK_LAST_SUBMISSION = "game/classroom/exam/check-last-submission";
export const GET_TIME_OPEN_SLIDE = "game/classroom/exam/get_time_open_slide";
export const CHECK_ATTENDANCES = "game/comment-lesson/check-attendances";
export const CHECK_COMMENT = "game/comment-lesson/check-comment";
export const ADMIN_HOMEWORK = "classrooms/exam/list-mark";
export const ADMIN_GET_MARK_HOMEWORK = "get-mark-homework-student";
export const ADMIN_MARK_HOMEWORK = "classrooms/exam/mark";
export const ADMIN_POST_MARK_HOMEWORK = "post-mark-homework-student";

// upload data class
export const GET_DATA_UPLOAD_CLASS = "game/classroom/upload/get-data-upload";
export const UPLOAD_DATA_CLASS = "game/classroom/upload/upload-data-class";
export const CREATE_MULTIPART_UPLOAD = "game/classroom/upload/create-multipart-upload";
export const DELETE_DATA_UPLOAD_CLASS = "game/classroom/upload/delete-data-class";
export const GET_INFO_DATA_UPLOAD_CLASS = "game/classroom/upload/get-info-data-upload-class";

// mutation types
export const SET_TUTORING = "set_tutoring";
export const UPDATE_TUTORING_DELETE = "update_tutoring_action";
export const UPDATE_TUTORING_UPDATE = "update_tutoring_update";

export const ADMIN_MARK_HOMEWORK_KID = "classrooms/exam/kid/mark";
export const STUDYING = 0;
export const ABSENT = 1;
export const STOP_THROUGH = 2;
export const STOP_STUDYING = 3; //ngừng học

export const LIST_INSTRUCT = [
    {
        classify: 'Rất tốt',
        content: 'Thái độ học tập rất tích cực, năng lực học tập nổi trội, tham gia đóng góp xây dựng bài thường xuyên, chăm chỉ làm bài tập và thực hành.'
    },
    {
        classify: 'Tốt',
        content: 'Thái độ học tập tốt, có đóng góp xây dựng bài, hiểu bài và tiếp thu nhanh.'
    },
    {
        classify: 'Khá',
        content: 'Có chú ý lắng nghe bài giảng nhưng chưa tích cực tham gia các hoạt động của lớp.'
    },
    {
        classify: 'Không tốt',
        content: 'Chưa tập trung trong các hoạt động của lớp. Chưa làm đầy đủ bài tập về nhà.'
    },
    {
        classify: 'Rất không tốt',
        content: 'Chưa nghiêm túc học tập, không tham gia các hoạt động của lớp gây ảnh hưởng tới các bạn khác.'
    },

];

export const OPTION_CLASSIFY = [
    {
        id: 5,
        value: 'Rất tốt',
    },
    {
        id: 4,
        value: 'Tốt',
    },
    {
        id: 3,
        value: 'Khá',
    },
    {
        id: 2,
        value: 'Không tốt',
    },
    {
        id: 1,
        value: 'Rất không tốt',
    },
];

export default {
    state: {
        tutorings : []
    },
    getters: {
        getTutorings(state){
            return state.tutorings;
        }
    },
    actions: {
        /**
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [ATTENDANCE_LESSON](context, payload) {
            return new Promise(resolve => {
                ApiService.query(`${ATTENDANCE_LESSON}`, payload).then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                    console.log(response)
                });
            })
        },
        [STUDENT_HAS_ATTENDANCE](context, payload) {
            return new Promise(resolve => {
                ApiService.query(`${STUDENT_HAS_ATTENDANCE}`, payload).then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                    console.log(response)
                });
            })
        },
        [STUDENT_HAS_GIFT](context, payload) {
            return new Promise(resolve => {
                ApiService.query(`${STUDENT_HAS_GIFT}`, payload).then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                    console.log(response)
                });
            })
        },
        [READY_MARK_HOMEWORK](context, payload) {
            return new Promise(resolve => {
                ApiService.query(`${READY_MARK_HOMEWORK}`, payload).then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                    console.log(response)
                });
            })
        },

        /**
         * @param context
         * @param id
         * @returns {Promise}
         */
        [ATTENDANCE_LESSON_PHU_DAO](context, id) {
            return new Promise((resolve, reject) => {
                ApiService.get(`${ATTENDANCE_LESSON_PHU_DAO}`, id).then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                    reject(response);
                });
            })
        },

        /**
         * Lộ trình lớp học
         * Params api : - classroom_id (required, integer)
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [CLASSROOM_SCHEDULE](context, payload) {
            return new Promise(resolve => {
                ApiService.query(`${CLASSROOM_SCHEDULE}`, payload).then(({data}) => {
                    context.commit(SET_TUTORING, data.data);
                    resolve(data);
                }).catch((response) => {
                    console.log(response)
                });
            })
        },

        /**
         * Danh sách nhận xét học viên
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [LIST_COMMENT](context, payload) {
            return new Promise(resolve => {
                ApiService.query(`${LIST_COMMENT}`, payload).then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                    console.log(response)
                });
            })
        },

        /**
         * Danh sách nhận xét học viên buổi phụ đạo
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [LIST_COMMENT_PHU_DAO](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.query(`${LIST_COMMENT_PHU_DAO}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response);
                });
            })
        },
        /**
         * Danh sách học viên và phòng
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [GET_DATA_CREATE_TUTORING](context, payload) {
            return new Promise(resolve => {
                ApiService.query(`${GET_DATA_CREATE_TUTORING}`, payload).then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                    console.log(response)
                });
            })
        },

        /**
         * Action nhận xét từng user theo buổi và theo lộ trình
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [POST_COMMENT](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.post(`${POST_COMMENT}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        /**
         * Action nhận xét từng user theo buổi phụ đạo
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [POST_COMMENT_PHU_DAO](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.post(`${POST_COMMENT_PHU_DAO}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },
        /**
         * Tạo buổi học phụ đạo
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [STORE_TUTORING](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.post(`${STORE_TUTORING}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        /**
         * Lấy data buổi học phụ đạo
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [GET_DATA_EDIT_TUTORING](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.get(`game/tutoring/edit`, payload.id).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        /**
         * Sửa buổi học phụ đạo
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [EDIT_TUTORING](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.put(`${EDIT_TUTORING}/${payload.id}`, payload.data).then(({data}) => {
                    context.commit(UPDATE_TUTORING_DELETE, data.data);
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        /**
         * xóa buổi học phụ đạo
         * @param context
         * @param id
         * @returns {Promise}
         */
        [DELETE_TUTORING](context, id) {
            return new Promise((resolve, reject) => {
                ApiService.delete(`${DELETE_TUTORING}`, id).then(({data}) => {
                    context.commit(UPDATE_TUTORING_DELETE, id);
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        /**
         * Api điểm danh
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [ACTION_ATTENDANCE](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.post(`${ACTION_ATTENDANCE}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        /**
         * Api điểm danh phụ đạo
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [ACTION_ATTENDANCE_PHU_DAO](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.post(`${ACTION_ATTENDANCE_PHU_DAO}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        /**
         * Api danh sách bài tập về nhà
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [EXAM_LIST_MARK](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.query(`${EXAM_LIST_MARK}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        /**
         * Api danh sách bài tập về nhà
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [EXAM_MARK](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.query(`${EXAM_MARK}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        /**
         * Api chấm điểm bài tập về nhà
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [EXAM_MARK_POST](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.post(`game/classroom/exam/mark`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        /**
         * Api kết quả bài kiểm tra
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [RESULT_EXAM](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.query(`${RESULT_EXAM}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        /**
         * Api danh sách phòng trống
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [LIST_EMPTY_ROOMS](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.query(`${LIST_EMPTY_ROOMS}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        /**
         * Api danh sách bài tập về nhà
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [GET_SCHEDULE_HOMEWORK](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.query(`${GET_SCHEDULE_HOMEWORK}/${payload.classroom_id}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        /**
         * Api kết quả bài tập về nhà
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [SHOW_HOMEWORK_RESULT](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.query(`${SHOW_HOMEWORK_RESULT}/${payload.classroom_id}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },
        [REQUEST_OFF_LESSON](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.post(`${REQUEST_OFF_LESSON}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        [GET_DATA_UPLOAD_CLASS](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.query(`${GET_DATA_UPLOAD_CLASS}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        // lấy số tư liệu của lớp theo buổi
        [GET_INFO_DATA_UPLOAD_CLASS](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.query(`${GET_INFO_DATA_UPLOAD_CLASS}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        [UPLOAD_DATA_CLASS](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.post(`${UPLOAD_DATA_CLASS}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        [CREATE_MULTIPART_UPLOAD](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.post(`${CREATE_MULTIPART_UPLOAD}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        [DELETE_DATA_UPLOAD_CLASS](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.post(`${DELETE_DATA_UPLOAD_CLASS}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        [CANCEL_REQUEST_OFF_LESSON](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.post(`${CANCEL_REQUEST_OFF_LESSON}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },
        [GET_NEXT_DATE_LESSON](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.query(`${GET_NEXT_DATE_LESSON}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },
        /**
         * api chi tiết từng buổi học trong lộ trình
         * @param context
         * @param payload
         * @returns {Promise}
         */
        [SHOW_DETAIL_LEARNING_SCHEDULE](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.query(`${SHOW_DETAIL_LEARNING_SCHEDULE}/${payload.schedule_id}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },



        [CHECK_LAST_SUBMISSION](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.query(`${CHECK_LAST_SUBMISSION}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        [GET_TIME_OPEN_SLIDE](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.query(`${GET_TIME_OPEN_SLIDE}`, payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response)
                });
            })
        },

        [CHECK_ATTENDANCES](context, payload) {
            return new Promise(resolve => {
                ApiService.query(`${CHECK_ATTENDANCES}`, payload).then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                    console.log(response)
                });
            })
        },

        [CHECK_ATTENDANCES](context, payload) {
            return new Promise(resolve => {
                ApiService.query(`${CHECK_ATTENDANCES}`, payload).then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                    console.log(response)
                });
            })
        },

        [GET_LIST_STUDENT_COMMENT](context, payload) {
            return new Promise(resolve => {
                ApiService.query(`${GET_LIST_STUDENT_COMMENT}`, payload).then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                    console.log(response)
                });
            })
        },

        [GET_AUTO_COMMENT](context, payload) {
            return new Promise(resolve => {
                ApiService.post(`${GET_AUTO_COMMENT}`, payload).then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                    console.log(response)
                });
            })
        },

        [POST_COMMENT_STUDENT](context, payload) {
            return new Promise(resolve => {
                ApiService.post(`${POST_COMMENT_STUDENT}`, payload).then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                    console.log(response)
                });
            })
        },

        [CHECK_COMMENT](context, payload) {
            return new Promise(resolve => {
                ApiService.query(`${CHECK_COMMENT}`, payload).then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                    console.log(response)
                });
            })
        },
        [ADMIN_HOMEWORK](context, payload) {
            return new Promise(resolve => {
                ApiService.query(ADMIN_HOMEWORK, payload).then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                    console.log(response)
                });
            })
        },
        [ADMIN_GET_MARK_HOMEWORK](context, payload) {
            return new Promise(resolve => {
                ApiService.query(ADMIN_MARK_HOMEWORK, payload).then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                    console.log(response)
                });
            })
        },
        [ADMIN_POST_MARK_HOMEWORK](context, payload) {
            return new Promise(resolve => {
                ApiService.post(ADMIN_MARK_HOMEWORK, payload).then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                    console.log(response)
                });
            })
        },
        [ADMIN_MARK_HOMEWORK_KID](context, payload) {
            return new Promise(resolve => {
                ApiService.post(ADMIN_MARK_HOMEWORK_KID, payload).then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                    console.log(response)
                });
            })
        },
    },
    mutations: {
        [SET_TUTORING](state, data) {
            let tutorings = state.tutorings;
            tutorings = data.map(tutoring => {
                return tutoring.type === 'tutoring';
            })
        },

        [UPDATE_TUTORING_DELETE](state, id) {
            let tutorings = state.tutorings;
            let index = tutorings.findIndex(tutoring => {
                return tutoring.id === id;
            });
            if (index > -1){
                tutorings.splice(index, 1);
            }
        },

        [UPDATE_TUTORING_UPDATE](state, tutoring) {
            let tutorings = state.tutorings;
            let old = tutorings.find(item => {
                return item.id === tutoring.id;
            });
            old = tutoring;
        },
    }
};
